.root {
  position: relative;
  left: -100px;
}

.input {
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  padding: 12px 42px;
  width: 400px;
  border-radius: 10px;
  font-size: 16px;

  &:focus {
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
  }
}

.icon {
  width: 24px;
  height: 24px;
  opacity: 0.5;
  position: absolute;
  left: 12px;
  right: 12px;
  top: 12px;
}

.clearIcon {
  width: 24px;
  height: 24px;
  opacity: 0.3;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
